import React, { useCallback, useContext, useEffect, useState } from 'react';
import Header from 'components/layout/Header';
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import commonStyles from 'styles/common.module.scss';
import styles from './ViewCredentials.module.scss';
import CredentialService from 'services/api/CredentialService';
import { UserContext } from 'components/UserGuard';
import { useParams } from 'react-router-dom';
import Spinner from 'components/shared/Spinner';
import clipboards from 'assets/icons/clipboards.svg';
import { Completion, Completions } from 'store/types/Completions';
import PrintTranscriptButton from 'components/credentials/PrintTranscriptButton';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import TabPanel from 'components/shared/TabPanel';
import { errorMessage } from 'util/Request';
import Table from 'components/shared/Table';
import { TooltipInfo } from 'components/shared/TooltipInfo';
import { Credential } from 'store/types/Credentials';
import { TableColumn } from 'store/types/Table';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { pipelineTypes } from 'store/configs/CredentialTypes';
import CraftProCard from '../CraftProCard';
import { GridView, ViewAgenda, ViewAgendaOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { CompletionsGrid } from '../CompletionsGrid';
import { CredentialsGrid } from '../CredentialsGrid';

interface ViewCredentialsProps {
  providedNccerNumber?: string;
}

const ViewCredentials: React.FunctionComponent<ViewCredentialsProps> = ({ providedNccerNumber }) => {
  const { isMobile } = useWindowSize();
  const { nccerNumber } = useParams<{ nccerNumber: string }>();
  const nccerCardNumber = providedNccerNumber ?? nccerNumber;
  const { enqueueSnackbar } = useSnackbar();
  const { roles } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [credsState, setCreds] = useState<Completions>();
  const [canPrintFlag, setCanPrintFlag] = useState<boolean>(false);
  const [canPrintPipelineFlag, setCanPrintPipelineFlag] = useState<boolean>(false);
  const [tabValue, setValue] = React.useState(0);
  const [viewType, setViewType] = React.useState<boolean>(true); // false = list/grid, true = tile
  const paginationLength = 10;

  const trainingModules = credsState?.Completions?.filter(
    (item) => item.Content.Type === 'Module' && !item.Content.Format && !pipelineTypes.includes(item.Content.Family)
  )
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId))
    .sort((a, b) => a.Content.Family?.localeCompare(b.Content.Family));
  const trainingLevels = credsState?.Completions?.filter((item) => item.Content.Type === 'Level').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingCrafts = credsState?.Completions?.filter((item) => item.Content.Type === 'Craft').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const partialModules = credsState?.Completions?.filter(
    (item) => item.Content.Type === 'Module' && item.Content.Format && !pipelineTypes.includes(item.Content.Family)
  )
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId))
    .sort((a, b) => a.Content.Family?.localeCompare(b.Content.Family));
  const trainingTests = credsState?.Credentials?.filter((item) => item.ContentType === 'Test').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const trainingCount =
    (trainingModules?.length ?? 0) +
    (trainingLevels?.length ?? 0) +
    (trainingCrafts?.length ?? 0) +
    (partialModules?.length ?? 0) +
    (trainingTests?.length ?? 0);

  const knowledgeVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Knowledge Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const performanceVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Performance Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const jlaCount = (knowledgeVerifications?.length ?? 0) + (performanceVerifications?.length ?? 0);

  const craftCertifications = credsState?.Credentials?.filter((item) => item.ContentType == 'Certified').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const personnelCertifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Program Personnel'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const certificateCount = (craftCertifications?.length ?? 0) + (personnelCertifications?.length ?? 0);

  const fullModulePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && !item.Content.Format
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const writtenPipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Written'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const performancePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Performance'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const pipelineCount =
    (fullModulePipelineCompletions?.length ?? 0) +
    (writtenPipelineCompletions?.length ?? 0) +
    (performancePipelineCompletions?.length ?? 0);

  const cpCourses = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Course', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const cpLearningPaths = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Path', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const craftProCount = (cpCourses?.length ?? 0) + (cpLearningPaths?.length ?? 0);

  const allowPrintRoles: string[] = [
    'Director',
    'Manager',
    'Program Contact',
    'Instructor',
    'Master Trainer',
    'Assessment Proctor',
    'Module Test Proctor',
  ];
  function canUserPrintTranscript() {
    roles?.forEach((role) => {
      if (allowPrintRoles.indexOf(role.role) > -1) {
        setCanPrintFlag(true);
      }
    });
  }
  function canUserPrintPipelineTranscript() {
    roles?.forEach((role) => {
      if (allowPrintRoles.indexOf(role.role) > -1) {
        setCanPrintPipelineFlag(true);
      }
    });
  }

  const pageLoad = async () => {
    setLoading(true);
    canUserPrintTranscript();
    canUserPrintPipelineTranscript();
    try {
      const completions = await CredentialService.getCompletions(nccerCardNumber ?? '', 'true', true);
      setCreds(completions);
    } catch (error) {
      enqueueSnackbar(errorMessage(error), { variant: 'error' });
      setCreds(undefined);
    }
    setLoading(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setValue(Number(event.target.value));
  };

  const handleListView = useCallback(() => {
    setViewType(false);
  }, []);

  const handleGridView = useCallback(() => {
    setViewType(true);
  }, []);

  const getListIcon = () => {
    if (viewType) {
      return <ViewAgendaOutlined className={commonStyles.listViewIcon} />;
    } else {
      return <ViewAgenda className={commonStyles.listViewIcon} />;
    }
  };

  const getGridIcon = () => {
    if (viewType) {
      return <GridViewRoundedIcon className={commonStyles.gridViewIcon} />;
    } else {
      return <GridView className={commonStyles.gridViewIcon} />;
    }
  };

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(errorMessage(error), { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const pipelineCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
  ];

  const credentialColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'ExpireDate',
      label: <Typography fontWeight="bold">Expiration Date</Typography>,
      render: (_, record) => (
        <Typography>{record.ExpireDate ? moment(record.ExpireDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const assessmentColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const levelCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const moduleCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const partialModuleCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Written/Performance Completed</Typography>,
      render: (_, record) => <Typography>{record.Content.Format}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
  ];

  const trainingTestColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProCourseColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    // {
    //   dataIndex: "Content",
    //   label: <Typography fontWeight="bold">Craft</Typography>,
    //   render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    // },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProLearningPathColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  return (
    <>
      {!isMobile && <Header />}
      <div className={styles.viewCredentialsHeader}>
        <Grid {...defaultGridContainerProps} className={styles.nameplateRow}>
          <Grid {...defaultGridItemProps} md={8} xl={9} className={commonStyles.nameplateWrapper}>
            <Grid item xs={2} md={1} xl={1} className={commonStyles.smallProfileImageWrapper}>
              {credsState?.LogoUrl ? (
                <img className={commonStyles.smallProfileImage} src={credsState.LogoUrl} />
              ) : (
                <AccountCircleIcon className={commonStyles.smallProfileImage}></AccountCircleIcon>
              )}
            </Grid>
            <Grid item xs={10} md={7} xl={9} className={commonStyles.nameplate}>
              <Typography variant={'h2'}>
                {credsState?.FirstName} {credsState?.LastName}
              </Typography>
              <Typography variant={'body2'}>{credsState?.CardNumber}</Typography>
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps} md={4} xl={3} className={styles.printTranscriptWrapper}>
            {canPrintFlag && tabValue == 0 && (
              <PrintTranscriptButton
                providedNccerNumber={nccerCardNumber}
                loading={loading}
                setLoading={setLoading}
                isFullWidth={false}
                className={styles.printBtn}
              />
            )}
            {canPrintPipelineFlag && tabValue == 4 && (
              <PrintTranscriptButton
                providedNccerNumber={nccerCardNumber}
                loading={loading}
                setLoading={setLoading}
                isFullWidth={false}
                className={styles.printBtn}
                isPipeline={true}
              />
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              visibleScrollbar={true}
            >
              <Tab
                label={'Accredited Training'}
                icon={<Typography className={commonStyles.credentialCounter}>{trainingCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'CraftPro'}
                icon={<Typography className={commonStyles.credentialCounter}>{craftProCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Journey-Level Assessments'}
                icon={<Typography className={commonStyles.credentialCounter}>{jlaCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Certifications'}
                icon={<Typography className={commonStyles.credentialCounter}>{certificateCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Pipeline OQ'}
                icon={<Typography className={commonStyles.credentialCounter}>{pipelineCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
            </Tabs>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps} lg={10} md={9} sm={8}>
                <Typography variant={'body2'} className={commonStyles.tabDescriptor}>
                  {tabValue == 0 &&
                    'This training is delivered by organizations that have met the NCCER accreditation and personnel training requirements. NCCER curriculum is modular in format and presented as a multi-level curriculum that can be used to meet the DOL Office of Apprenticeship time requirements.'}
                  {tabValue == 1 &&
                    'CraftPro training consists of NCCER online courses selected by a company based on their unique project and skill requirements, and do not require accreditation.'}
                  {tabValue == 2 &&
                    'These assessments and performance verifications are intended to measure the knowledge and skills of experienced craft professionals.'}
                  {tabValue == 3 &&
                    'Certifications represent completion of both knowledge and performance evaluations. Note: this section also displays program personnel certifications.'}
                  {tabValue == 4 &&
                    'Pipeline OQ are written and/or performance completions needed to qualify individuals to perform activities on a pipeline according to operator qualification requirements.'}
                </Typography>
              </Grid>
              <Grid {...defaultGridItemProps} lg={2} md={3} sm={4} className={commonStyles.viewSelectorWrapper}>
                <ButtonGroup variant={'contained'} className={commonStyles.viewSelector}>
                  <IconButton
                    onClick={handleGridView}
                    className={classNames(commonStyles.gridViewButton, viewType ? commonStyles.activeViewButton : '')}
                  >
                    {getGridIcon()}
                    <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                      Grid
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={handleListView}
                    className={classNames(commonStyles.listViewButton, !viewType ? commonStyles.activeViewButton : '')}
                  >
                    {getListIcon()}
                    <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                      List
                    </Typography>
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        )}

        {isMobile && (
          <>
            <div className={styles.divider}></div>
            <FormControl fullWidth>
              <div>
                <Select sx={{ width: '87%' }} value={tabValue.toString()} onChange={handleDropdownChange}>
                  <MenuItem value={0}>{`Accredited Training  (${trainingCount.toString()})`}</MenuItem>
                  <MenuItem value={1}>{`CraftPro  (${craftProCount.toString()})`}</MenuItem>
                  <MenuItem value={2}>{`Journey-Level Assessments  (${jlaCount.toString()})`}</MenuItem>
                  <MenuItem value={3}>{`Certifications  (${certificateCount.toString()})`}</MenuItem>
                  <MenuItem value={4}>{`Pipeline OQ  (${pipelineCount.toString()})`}</MenuItem>
                </Select>
                {!viewType ? (
                  <IconButton sx={{ width: '13%' }} onClick={handleGridView}>
                    <GridViewRoundedIcon />
                  </IconButton>
                ) : (
                  <IconButton sx={{ width: '13%' }} onClick={handleListView}>
                    <ViewAgenda />
                  </IconButton>
                )}
              </div>
            </FormControl>
          </>
        )}
      </div>
      <Spinner loading={loading}>
        <div className={commonStyles.pageContent}>
          <TabPanel value={tabValue} index={0}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={trainingCrafts}
                      columns={craftCompletionColumns}
                      noResultsText="No crafts earned"
                      showPagination={trainingCrafts && trainingCrafts.length > paginationLength}
                      category="Completed Crafts"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingLevels}
                      columns={levelCompletionColumns}
                      noResultsText="No levels earned"
                      showPagination={trainingLevels && trainingLevels.length > paginationLength}
                      category="Completed Levels"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingModules}
                      columns={moduleCompletionColumns}
                      noResultsText="No modules earned"
                      showPagination={trainingModules && trainingModules.length > paginationLength}
                      category="Completed Modules"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={partialModules}
                      columns={partialModuleCompletionColumns}
                      noResultsText="No partial modules earned"
                      showPagination={partialModules && partialModules.length > paginationLength}
                      category="Partial Module Completions"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingTests}
                      columns={trainingTestColumns}
                      noResultsText="No tests completed"
                      showPagination={trainingTests && trainingTests.length > paginationLength}
                      category="Specialized Test Certificates"
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <CompletionsGrid
                    title={
                      <>
                        Craft Completions
                        <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                      </>
                    }
                    completions={trainingCrafts}
                  />

                  <CompletionsGrid
                    title={
                      <>
                        Level Completions
                        <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                      </>
                    }
                    completions={trainingLevels}
                  />

                  <CompletionsGrid
                    title={
                      <>
                        Module Completions
                        <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                      </>
                    }
                    completions={trainingModules}
                  />

                  <CompletionsGrid title="Partial Module Completions" completions={partialModules} showBadge={false} />

                  <CredentialsGrid
                    title="Specialized Test Certificates"
                    credentials={trainingTests}
                    showBadge={false}
                  />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={cpLearningPaths}
                      columns={craftProLearningPathColumns}
                      category="Learning Path Credentials"
                      noResultsText="No learning path credentials earned"
                      showPagination={cpLearningPaths && cpLearningPaths.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={cpCourses}
                      columns={craftProCourseColumns}
                      category="Course Completions"
                      noResultsText="No course completions earned"
                      showPagination={cpCourses && cpCourses.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Learning Paths
                    <TooltipInfo title="Collections of NCCER courses, curated by companies to meet specific training goals." />
                  </Typography>
                </Grid>
              )}
              {viewType &&
                cpLearningPaths &&
                cpLearningPaths.map((item, index) => (
                  <Grid {...defaultGridItemProps} xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
                    <CraftProCard item={item} showContentType={false} />
                  </Grid>
                ))}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    All Courses
                    <TooltipInfo title="Individual training requirements that make up each Learning Path." />
                  </Typography>
                </Grid>
              )}
              {viewType &&
                cpCourses &&
                cpCourses.map((item, index) => (
                  <Grid {...defaultGridItemProps} xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
                    <CraftProCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={knowledgeVerifications}
                      columns={assessmentColumns}
                      category="Knowledge Verified"
                      noResultsText="No credentials earned"
                      showPagination={knowledgeVerifications && knowledgeVerifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={performanceVerifications}
                      columns={assessmentColumns}
                      category="Performance Verified"
                      noResultsText="No credentials earned"
                      showPagination={performanceVerifications && performanceVerifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <CredentialsGrid title="Knowledge Verified" credentials={knowledgeVerifications} size="lg" />

                  <CredentialsGrid title="Performance Verified" credentials={performanceVerifications} size="lg" />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={personnelCertifications}
                      columns={credentialColumns}
                      category="Program Personnel Certifications"
                      noResultsText="No credentials earned"
                      showPagination={personnelCertifications && personnelCertifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={craftCertifications}
                      columns={credentialColumns}
                      category="Craft Certifications"
                      noResultsText="No credentials earned"
                      showPagination={craftCertifications && craftCertifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <CredentialsGrid
                    title="Program Personnel Certifications"
                    credentials={personnelCertifications}
                    showBadge
                  />

                  <CredentialsGrid title="Craft Certifications" credentials={craftCertifications} />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={fullModulePipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Completed Modules"
                      noResultsText="No module completions earned"
                      showPagination={
                        fullModulePipelineCompletions && fullModulePipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={writtenPipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Written Completions"
                      noResultsText="No written completions earned"
                      showPagination={
                        writtenPipelineCompletions && writtenPipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={performancePipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Performance Completions"
                      noResultsText="No performance completions earned"
                      showPagination={
                        performancePipelineCompletions && performancePipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <CompletionsGrid title="Completed Modules" completions={fullModulePipelineCompletions} size="lg" />

                  <CompletionsGrid title="Written Completions" completions={writtenPipelineCompletions} size="lg" />

                  <CompletionsGrid
                    title="Performance Completions"
                    completions={performancePipelineCompletions}
                    size="lg"
                  />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          {credsState?.Completions?.length == 0 && credsState?.Credentials?.length == 0 && !loading && (
            <Grid item xs={12} className={commonStyles.clipboardBox}>
              <img src={clipboards} />
              <Typography paragraph={true} className={commonStyles.text}>
                {'There are currently no training records in our system.'}
              </Typography>
            </Grid>
          )}
        </div>
      </Spinner>
    </>
  );
};
export default ViewCredentials;
