import { Box, Typography } from '@mui/material';
import CredentialCard from 'components/credentials/CredentialCard';
import { Credential } from 'store/types/Credentials';

import commonStyles from 'styles/common.module.scss';

export interface CredentialsGridProps {
  title: React.ReactNode;
  credentials: Credential[] | undefined;
  isPublic?: boolean;
  showBadge?: boolean;
  size?: 'md' | 'lg';
}

export function CredentialsGrid({ title, credentials, isPublic, showBadge = true, size }: CredentialsGridProps) {
  return (
    <>
      <Typography variant={'h6'} pb={2} className={commonStyles.credentialCategory}>
        {title}
      </Typography>
      {credentials && (
        <Box display="flex" flexWrap="wrap" gap={3} pb={3}>
          {credentials.map((item, index) => (
            <CredentialCard key={index} item={item} isPublic={isPublic} showBadge={showBadge} size={size} />
          ))}
        </Box>
      )}
    </>
  );
}
