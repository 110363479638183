import { Box, Typography } from '@mui/material';
import CompletionCard from 'components/credentials/CompletionCard';
import { Completion } from 'store/types/Completions';

import commonStyles from 'styles/common.module.scss';

export interface CompletionsGridProps {
  title: React.ReactNode;
  completions: Completion[] | undefined;
  isPublic?: boolean;
  showBadge?: boolean;
  size?: 'md' | 'lg';
}

export function CompletionsGrid({ title, completions, isPublic, showBadge = true, size }: CompletionsGridProps) {
  return (
    <>
      <Typography variant={'h6'} pb={2} className={commonStyles.credentialCategory}>
        {title}
      </Typography>
      {completions && (
        <Box display="flex" flexWrap="wrap" gap={3} pb={3}>
          {completions.map((item, index) => (
            <CompletionCard key={index} item={item} isPublic={isPublic} showBadge={showBadge} size={size} />
          ))}
        </Box>
      )}
    </>
  );
}
