import { IconButton, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

export interface TooltipInfoProps {
  title: string;
}

export function TooltipInfo({ title }: TooltipInfoProps) {
  return (
    <Tooltip
      title={title}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#172535',
            '& .MuiTooltip-arrow': {
              color: '#172535',
            },
            padding: '12px',
          },
        },
      }}
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
}
