import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import classNames from 'classnames';
import { Credential } from 'store/types/Credentials';
import React, { useCallback, useMemo, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';
import Spinner from 'components/shared/Spinner';
import { Puller } from 'util/Layout';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';
import { useWindowSize } from 'util/Window';
import { BadgeImage } from '../BadgeImage';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/credentials/CompletionCard/CompletionCard.module.scss';

interface CredentialCardProps {
  item: Credential;
  isPublic?: boolean;
  showBadge?: boolean;
  size?: 'md' | 'lg';
}

const CredentialCard: React.FunctionComponent<CredentialCardProps> = ({
  item,
  isPublic = false,
  showBadge = false,
  size = 'md',
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const docLink = item?.Documents[0]?.Link;
  const detailLink = isPublic
    ? routes.publicBadgeDetails + '?id=' + item.CompletionId
    : routes.badgeDetails + '?id=' + item.CompletionId;
  const imageUrl = item.CredlyBadgeImageUrl;
  const isExpired = Boolean(item.ExpireDate && moment(item.ExpireDate) < moment());

  const dimension = useMemo(() => (size === 'lg' ? 300 : 220), [size]);

  const toggleDrawer = (newOpen: boolean) => () => {
    isMobile && setOpenDrawer(newOpen);
  };

  const handlePrintClick = useCallback(() => {
    setLoading(true);
    CredentialService.getDocumentLink(docLink)
      .then((response) => {
        setLoading(false);
        window.location.assign(response);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error?.body, { variant: 'error' });
      });
  }, [docLink, enqueueSnackbar]);

  return (
    <Spinner loading={loading} fullPage={false}>
      {showBadge && imageUrl ? (
        // Badge Image Display
        <BadgeImage
          imageUrl={imageUrl}
          docLink={docLink}
          detailLink={detailLink}
          isExpired={isExpired}
          isMobile={isMobile}
          dimension={dimension}
          onClick={toggleDrawer(true)}
          onPrint={handlePrintClick}
        />
      ) : (
        // Tile/Card Display
        <Box
          height={dimension}
          width={dimension}
          className={classNames(commonStyles.contentCard, styles.credentialCard)}
        >
          <Typography className={styles.cardTitle} variant={'body1'}>
            {item.CredentialName}
          </Typography>
          {item.CredentialDate && (
            <Typography variant={'body2'}>Date Earned: {moment(item.CredentialDate).format('L')}</Typography>
          )}
          {item.ExpireDate && (
            <Typography variant={'body2'}>Expiration Date: {moment(item.ExpireDate).format('L')}</Typography>
          )}
          <Typography variant={'body2'}>{item.ContentType}</Typography>
          {item.Documents && item.Documents[0]?.Link ? (
            <>
              <br />
              <Button
                onClick={handlePrintClick}
                variant={'contained'}
                color={'primary'}
                className={styles.printCredentialBtn}
              >
                {'View/Print'}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      )}
      {isMobile && (
        <SwipeableDrawer
          open={openDrawer}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
          anchor={'bottom'}
          swipeAreaWidth={56}
          ModalProps={{
            keepMounted: false,
          }}
        >
          <div className={styles.drawerWrapper}>
            <Puller />
            {docLink && (
              <Typography
                onClick={handlePrintClick}
                className={classNames(styles.drawerOption, styles.mobileDownloadLink)}
              >
                {/* <VisibilityOutlinedIcon />  */}
                {'Print Credential'}
              </Typography>
            )}
            <NavLink
              to={routes.badgeDetails + '?id=' + item.CompletionId}
              className={classNames(styles.drawerOption, styles.mobileDetailsLink)}
            >
              {/* <ArrowCircleDownOutlinedIcon /> */}
              {'View Details'}
            </NavLink>
          </div>
        </SwipeableDrawer>
      )}
    </Spinner>
  );
};
export default CredentialCard;
