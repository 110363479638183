import { Grid, Typography } from '@mui/material';
import Logo from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import { BadgeDetails } from '../BadgeDetailsPage';

import styles from '../PublicBadgeDetailsPage/PublicBadgeDetailsPage.module.scss';

export function PublicBadgeDetailsPage() {
  return (
    <>
      <div className={styles.ovHeader}>
        <Grid container>
          <Grid item xs={2}>
            <img src={Logo} className={styles.ovLogo} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant={'h1'} className={styles.searchedTitle}>
              {'Credential Search'}
            </Typography>
            <Typography variant={'h5'} className={styles.searchedDescription}>
              {"Search and verify individuals' credentials"}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={styles.ovSearchedContent}>
        <BadgeDetails />
      </div>
    </>
  );
}
