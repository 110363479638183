import Header from 'components/layout/Header';
import { useWindowSize } from 'util/Window';
import { BadgeDetails } from './BadgeDetails';

export function BadgeDetailsPage() {
  const { isMobile } = useWindowSize();

  return (
    <>
      {!isMobile && <Header />}
      <BadgeDetails />
    </>
  );
}
