import { Box, Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import styles from './BadgeImage.module.scss';

interface BadgeImageProps {
  imageUrl: string;
  docLink: string;
  detailLink: string;
  isExpired: boolean;
  isMobile: boolean;
  dimension?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onPrint?: React.MouseEventHandler<HTMLButtonElement>;
}

export function BadgeImage({
  imageUrl,
  docLink,
  detailLink,
  isExpired,
  isMobile,
  dimension = 220,
  onClick,
  onPrint,
}: BadgeImageProps) {
  return (
    <Box
      position="relative"
      height={dimension}
      width={dimension}
      onClick={onClick}
      className={styles.overlayContainer}
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {isExpired && (
        <Box p={1}>
          <Typography variant="body2" className={styles.expiredTag}>
            Expired
          </Typography>
        </Box>
      )}
      {!isMobile && (
        <Box className={styles.hiddenOverlay}>
          {docLink && (
            <Button onClick={onPrint} className={styles.downloadLink}>
              Print Credential
            </Button>
          )}
          <NavLink to={detailLink} className={styles.viewDetailsLink}>
            View Details
          </NavLink>
        </Box>
      )}
    </Box>
  );
}
