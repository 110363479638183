import { Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';
import craftProStamp from 'assets/images/craftPro/craftProCredCardStamp.svg';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/credentials/CompletionCard/CompletionCard.module.scss';
import moment from 'moment';
import routes from 'store/configs/Routes';
import { Completion } from 'store/types/Completions';
import type { Credential } from 'store/types/Credentials';
import { getTypeSafeItem } from 'util/Credentials';
import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';

interface CraftProCardProps {
  item: Credential | Completion;
  showContentType?: boolean;
  isPublic?: boolean;
}

const CraftProCard: React.FunctionComponent<CraftProCardProps> = ({
  item,
  showContentType = true,
  isPublic = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const typeSafeItem: TypeSafeCredentialDetails = getTypeSafeItem(item);
  const docLink = typeSafeItem.Document?.Link;
  const detailLink = isPublic
    ? routes.publicBadgeDetails + '?id=' + typeSafeItem.CompletionId + '&iscp=true'
    : routes.badgeDetails + '?id=' + typeSafeItem.CompletionId + '&iscp=true';

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error?.body, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return (
    <div className={classNames(commonStyles.contentCard, styles.credentialCard)}>
      {typeSafeItem.CraftProShowBadge && typeSafeItem.CertificateLogoUrl && (
        <img src={typeSafeItem.CertificateLogoUrl} className={styles.cardBadge} />
      )}
      <Typography className={styles.cardTitle} variant={'body1'}>
        {!typeSafeItem.CraftProShowBadge && <img src={craftProStamp} className={styles.stamp} />}
        {typeSafeItem.Name}
      </Typography>
      {typeSafeItem.Date && (
        <Typography variant={'body2'}>Date Earned: {moment(typeSafeItem.Date).format('L')}</Typography>
      )}
      {typeSafeItem.ExpireDate && (
        <Typography variant={'body2'}>Expiration Date: {moment(typeSafeItem.ExpireDate).format('L')}</Typography>
      )}
      {typeSafeItem.ContentFamily && <Typography variant={'body2'}>{typeSafeItem.ContentFamily}</Typography>}
      {/* <NavLink
        to={detailLink}
        className={styles.detailsLink}
      >
        <Button variant={'contained'} color={'primary'} fullWidth={true} className={styles.printCredentialBtn}>
          {docLink ? 'View/Print' : 'View Details'}
        </Button>
      </NavLink> */}
      {/* {item.Documents && item.Documents[0]?.Link ? (
        <Button
          onClick={() => handlePrintClick(item.Documents[0].Link)}
          variant={"contained"}
          color={"primary"}
          className={styles.printCredentialBtn}
        >
          {"View/Print"}
        </Button>
      ) : (
        <></>
      )} */}
    </div>
  );
};
export default CraftProCard;
