import MainPageLayout from 'components/layout/MainPageLayout';
import LoginPage from 'components/login/LoginPage';
import RedirectPage from 'components/login/RedirectPage';
import SessionChecker from 'components/SessionChecker';
import ProtectedRoute from 'components/shared/ProtectedRoute';
import UserGuard from 'components/UserGuard';
import { tenantConfig } from 'config';
import React, { useMemo } from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import authClient from 'services/AuthService';
import routes from 'store/configs/Routes';
import { OnlineVerification } from './credentials/OnlineVerification/OnlineVerification';
import { PublicBadgeDetailsPage } from './credentials/PublicBadgeDetailsPage/PublicBadgeDetailsPage';
import LogoutLandingPage from './login/LogoutLandingPage/LogoutLandingPage';
import SessionTabsContext from 'components/SessionTabsContext';

const checkAuthRoute =
  (children: React.ReactNode) =>
  ({ location }: RouteChildrenProps) => {
    if (tenantConfig.loginUrl) {
      window.location.assign(tenantConfig.loginUrl);
      return <></>;
    }
    if (authClient.isAuthenticated()) {
      if (location.pathname.includes(routes.setPassword)) {
        authClient.logout();
        return children;
      } else {
        return <Redirect to={'/'} />;
      }
    } else {
      return children;
    }
  };

const AppPublicRoutes: React.FunctionComponent = () => {
  const routesContent: React.ReactNode[] = useMemo(() => {
    const publicRoutes: React.ReactNode[] = [
      <Route key={'redirect'} path={routes.redirect} exact={true}>
        <RedirectPage />
      </Route>,
      <Route
        key={'login'}
        path={[
          routes.login,
          `${routes.setPassword}/:username/:requestCode`,
          `${routes.firstLogin}/:username/:tempPassword`,
          `${routes.firstLogin}/:username/`,
        ]}
        exact={true}
      >
        {checkAuthRoute(<LoginPage />)}
      </Route>,
      <Route
        key={'onlineVerification'}
        path={[routes.onlineVerification, `${routes.onlineVerification}/:searchText`]}
        exact={true}
      >
        <OnlineVerification />
      </Route>,
      <Route
        key={'publicBadgeDetails'}
        path={[routes.publicBadgeDetails, `${routes.publicBadgeDetails}/:name`]}
        exact={true}
      >
        <PublicBadgeDetailsPage />
      </Route>,
      <Route key={'logout'} path={routes.logout} exact={true}>
        <LogoutLandingPage />
      </Route>,
    ];
    const protectedRouteContent: React.ReactNode = <MainPageLayout />;

    return [
      ...publicRoutes,
      <ProtectedRoute path={'*'} exact={true} key={'logged-in-routes'}>
        {protectedRouteContent}
      </ProtectedRoute>,
    ];
  }, []);
  const content: React.ReactNode = useMemo(() => {
    const result: React.ReactNode = <Switch>{routesContent}</Switch>;

    return result;
  }, [routesContent]);

  return (
    <Switch>
      <Route path={'*'} exact={true}>
        <SessionTabsContext>
          <UserGuard>
            {content}
            <SessionChecker />
          </UserGuard>
        </SessionTabsContext>
      </Route>
    </Switch>
  );
};
export default AppPublicRoutes;
