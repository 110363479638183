export interface CredentialCourse {
  title: string;
  craft: string;
  description: string;
}

export const courseList: CredentialCourse[] = [
  {
    title: 'Wall Systems',
    craft: 'Carpentry',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  },
  {
    title: 'Safety 101',
    craft: 'Core',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  },
  {
    title: 'Floor Systems',
    craft: 'Carpentry',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  },
  {
    title: 'Wiring',
    craft: 'Electrical',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  },
  {
    title: 'Building Envelopes',
    craft: 'Carpentry',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
  },
];

export const categoryText = `Courses (${courseList.length})`;
