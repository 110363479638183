import { Completion } from 'store/types/Completions';
import { Credential } from 'store/types/Credentials';
import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';

export function isTraining(type: string) {
  if (type == 'Module' || type == 'Craft' || type == 'Level') return true;
  else return false;
}

export function isVerification(type: string) {
  if (type == 'Knowledge Verified' || type == 'Performance Verified') return true;
  else return false;
}

export function isCertification(type: string) {
  if (type == 'Certified' || type == 'Program Personnel') return true;
  else return false;
}

export function getFamilyFormatText(item: TypeSafeCredentialDetails) {
  if (item.ContentFamily && item.ContentFormat) {
    return item.ContentFamily + ' : ' + item.ContentFormat;
  } else if (!item.ContentFormat) {
    return item.ContentFamily;
  } else if (!item.ContentFamily) {
    return item.ContentFormat;
  } else {
    return '';
  }
}

export function getTypeSafeItem(item: Completion | Credential): TypeSafeCredentialDetails {
  if ('CredentialName' in item) {
    const temp = item as Credential;
    return {
      CompletionId: temp.CompletionId,
      Name: temp.CredentialName,
      Date: temp.CredentialDate,
      ExpireDate: temp.ExpireDate,
      ContentType: temp.ContentType,
      Document: temp.Documents[0] ?? null,

      //Badge Details
      CredlyBadgeTitle: temp.CredlyBadgeTitle,
      CredlyBadgeDescription: temp.CredlyBadgeDescription,
      CredlyBadgeImageUrl: temp.CredlyBadgeImageUrl,
      CredlyBadgeSkills: temp.CredlyBadgeSkills,

      // CraftPro
      CraftProShowBadge: false,
    };
  } else {
    const temp = item as Completion;
    return {
      CompletionId: temp.CompletionId,
      Name: temp.Content.Name,
      Date: temp.SubmissionDate,
      ExpireDate: temp.ExpirationDate,
      SubmissionDate: temp.SubmissionDate,
      ContentId: temp.Content.ContentId,
      ContentType: temp.Content.Type,
      ContentFamily: temp.Content.Family,
      ContentFormat: temp.Content.Format,
      Document: temp.Document ?? null,
      Description: temp.Content.CredlyBadgeDescription,
      CertificateLogoUrl: temp.Content.CertificateLogoUrl,

      //Badge Details
      CredlyBadgeTitle: temp.Content.CredlyBadgeTitle,
      CredlyBadgeDescription: temp.Content.CredlyBadgeDescription,
      CredlyBadgeImageUrl: temp.Content.CredlyBadgeImageUrl,
      CredlyBadgeSkills: temp.Content.CredlyBadgeSkills,

      // CraftPro
      CraftProShowBadge:
        temp.Content.CertificateLogoUrl !== undefined &&
        temp.Content.CertificateLogoUrl !== null &&
        temp.Content.CertificateLogoUrl !== '',
    };
  }
}
